<template>
  <div class="index">
    <!-- element ui 布局容器 -->
    <el-container>
      <el-header>
        <!-- 第一部分 logo -->
        <div class="logo">
          <img src="../../assets/image/EquipmentStorage_slices/group_66.png" alt="" width="122.61vw" height="27.24vh" />
        </div>
        <!-- 第二部分 头部内容区域 -->
        <div class="logo_right">
          <h1 style="font-size: 24px">智慧用电安全云平台后台管理系统</h1>
          <div class="right_logout">
            <img src="../../assets/image/EquipmentStorage_slices/group_1.png" alt="" width="24vh" height="24vh" />
            <span style="margin-left: 0.628vw; cursor: pointer" class="manager">管理员</span>
            <img src="../../assets/image/EquipmentStorage_slices/group_2.png" alt="" width="24vh" height="24vh"
              style="margin-left: 1.98vw" />
            <span style="margin: 0 5.56vw 0 0.625vw; cursor: pointer" @click="logOut">退出</span>
          </div>
        </div>
      </el-header>
      <el-container>
        <!-- 第三部分 侧边栏 -->
        <el-aside width="12.5vw" style="background-color: #fff">
          <el-row class="tac">
            <el-col :span="24">
              <el-menu class="el-menu-vertical-demo" :default-openeds="['1', '2', '3', '4', '5', '6', '7']"
                :collapse="isCollapse" :router="true">
                <!-- 1.设备入库 -->
                <el-submenu index="1">
                  <template slot="title">
                    <img src="../../assets/image/EquipmentStorage_slices/path_1739.png" alt=""
                      style="margin-right: 14px" width="20px" height="20px" />
                    <span>设备入库</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item index="/gateWayWareHoushimg" style="
                        padding-left: 3.81vw;
                        height: 5.55vh;
                        line-height: 5.55vh;
                      ">网关管理
                    </el-menu-item>
                    <!-- <el-menu-item index="/nicManage" style="padding-left: 3.81vw;height:5.55vh;line-height: 5.55vh;">物联网卡管理
                    </el-menu-item> -->
                    <!-- <el-menu-item index="/withDrawalApplication" style="padding-left: 3.81vw;height:5.55vh;line-height: 5.55vh;">退库申请
                    </el-menu-item> -->
                  </el-menu-item-group>
                </el-submenu>
                <!-- 2.客户管理 -->
                <el-submenu index="2">
                  <template slot="title">
                    <img src="../../assets/image/EquipmentStorage_slices/path_1744.png" alt=""
                      style="margin-right: 14px" width="20px" height="20px" />
                    <span>客户管理</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item index="/customerInformation" style="
                        padding-left: 3.81vw;
                        height: 5.55vh;
                        line-height: 5.55vh;
                      ">信息录入
                    </el-menu-item>
                    <el-menu-item index="/customerEquipment" style="
                        padding-left: 3.81vw;
                        height: 5.55vh;
                        line-height: 5.55vh;
                      ">客户设备
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
                <!-- 4.报警信息 -->
                <el-submenu index="4">
                  <template slot="title">
                    <img src="../../assets/image/EquipmentStorage_slices/path_1738.png" alt=""
                      style="margin-right: 14px" width="20px" height="20px" />
                    <span>报警信息</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item index="/warningInform" style="
                        padding-left: 3.81vw;
                        height: 5.55vh;
                        line-height: 5.55vh;
                      ">报警事件
                    </el-menu-item>
                    <el-menu-item index="/offlineDevices" style="
                        padding-left: 3.81vw;
                        height: 5.55vh;
                        line-height: 5.55vh;
                      ">离线设备
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
                <!-- 5.代理商管理 -->
                <el-submenu index="5">
                  <template slot="title">
                    <img src="../../assets/image/EquipmentStorage_slices/path_1741.png" alt=""
                      style="margin-right: 14px" width="20px" height="20px" />
                    <span>代理商管理</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item index="/agentManage" style="
                        padding-left: 3.81vw;
                        height: 5.55vh;
                        line-height: 5.55vh;
                      ">代理商管理
                    </el-menu-item>
                    <!-- <el-menu-item index="/purchaseManage" style="padding-left: 3.81vw;height:5.55vh;line-height: 5.55vh;">采购管理
                    </el-menu-item> -->
                    <!--<el-menu-item index="/productInformMaintain" style="
                        padding-left: 3.81vw;
                        height: 5.55vh;
                        line-height: 5.55vh;
                      ">产品信息维护
                    </el-menu-item>-->
                  </el-menu-item-group>
                </el-submenu>
                <!-- 6.项目报备 -->
                <!-- <el-submenu index="6" class="project_filing">
                  <template slot="title">
                    <img src="../../assets/image/EquipmentStorage_slices/path_1743.png" alt=""
                      style="margin-right: 14px;" width="20px" height="20px">
                    <span>项目报备</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item index="/projectFiling" style="padding-left: 3.81vw;height:5.55vh;line-height: 5.55vh;">项目报备
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu> -->
                <!-- 7.系统设置 -->
                <el-submenu index="7">
                  <template slot="title">
                    <img src="../../assets/image/EquipmentStorage_slices/path_1740.png" alt=""
                      style="margin-right: 14px" width="20px" height="20px" />
                    <span>系统设置</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item index="/accountManage" style="
                        padding-left: 3.81vw;
                        height: 5.55vh;
                        line-height: 5.55vh;
                      ">账户管理
                    </el-menu-item>
                    <el-menu-item index="/journalManage" style="
                        padding-left: 3.81vw;
                        height: 5.55vh;
                        line-height: 5.55vh;
                      ">日志管理
                    </el-menu-item>
                    <el-menu-item index="/uploadApp" style="
                        padding-left: 3.81vw;
                        height: 5.55vh;
                        line-height: 5.55vh;
                      ">APP管理
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
                <!-- 8 消息中心 -->
                <!--<el-menu-item index="/messagenews" style="
                        height: 5.55vh;
                        line-height: 5.55vh;
                      ">
                  <img class="aa" src="../../assets/image/EquipmentStorage_slices/message1.png" alt=""
                    style="margin-right: 14px" width="20px" height="20px" />
                  <span>消息中心</span>
                </el-menu-item>-->
                <!-- <template slot="title">
                    <img
                      src="../../assets/image/EquipmentStorage_slices/path_1739.png"
                      alt=""
                      style="margin-right: 14px"
                      width="20px"
                      height="20px"
                    />
                    <span>消息中心</span>
                  </template> -->
                <!--  -->
              </el-menu>
            </el-col>
          </el-row>
        </el-aside>
        <!-- 主体区域 -->
        <el-main>
          <!-- 设置路由出口 -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { Message } from "element-ui";
export default {
  data () {
    return {
      isCollapse: false,
    };
  },
  mounted () {
    // 获取屏幕的可视宽高
    this.visualScreen();
    // 获取cookie
    this.getCookie();
  },
  updated () {
    this.visualScreen();
  },

  methods: {
    // 1.实时屏幕宽度
    visualScreen () {
      //  获取侧边栏元素
      let elAside = document.querySelector(".el-aside");
      //  获取el-submenu元素节点
      let elSubmenu = document.querySelector(".el-submenu");
      // 获取字体节点
      let hFont = document.getElementsByTagName("h1")[0];

      // 启动程序时 获取屏幕的可视宽 一进来屏幕的可视宽高<=1200
      if (document.documentElement.clientWidth >= 1200) {
        this.isCollapse = false;
        elAside.style.width = 15.5 + "vw";
        elSubmenu.style.width = 15.5 + "vw";
      } else {
        this.isCollapse = true;
        elAside.style.width = 5.5 + "vw";
        elSubmenu.style.width = 5.5 + "vw";
      }

      window.onresize = () => {
        return (() => {
          // 获取屏幕的可视宽度
          let screenWidth = document.documentElement.clientWidth;
          // 获取当前屏幕的大小
          let sWidth = window.screen.width;
          // 缩小后的字体大小 =  字体的原大小/先在屏幕的宽度
          hFont.style.fontSize = Math.ceil((screenWidth / sWidth) * 24) + "px";
          if (screenWidth < 1200) {
            this.isCollapse = true;
            elAside.style.width = 5.5 + "vw";
            elSubmenu.style.width = 5.5 + "vw";
          } else {
            this.isCollapse = false;
            elAside.style.width = 15.5 + "vw";
            elSubmenu.style.width = 15.5 + "vw";
          }
        })();
      };
    },
    logOut () {
      //消除cookie
      let expdate = new Date();
      expdate.setTime(expdate.getDate() - 1); //设置cookie时间
      let userInform = null;
      document.cookie =
        `userInform=${userInform}` +
        ";expires=" +
        expdate.toGMTString() +
        ";path=/";
      // 消除session中的数据
      sessionStorage.removeItem("user");
      Message.success("退出成功");
      this.$router.push("/login");
    },
    //获取cookie
    getCookie () {
      let cookie = document.cookie;
      if (!cookie) {
        //没有登录
        this.$confirm("您还未登录，请先登录", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
          center: true,
        })
          .then(() => {
            this.$message({ type: "success", message: "请登录" });
            this.$router.push("/login");
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: "抱歉，您还未登录,请先登录",
            });
            this.$router.push("/login");
          });
      } else {
        //已登录
        // 获取session中的数据
        let user = JSON.parse(localStorage.getItem("user"));
        let managerDom = document.querySelector(".manager");
        managerDom.innerHTML = user.cuser;
      }
    },
  },
};
</script>

<style>
/* el-header头部--开始 */
.xiaoxi .el-submenu__icon-arrow {
  content: none;
}
.xiaoxi .el-submenu__icon-arrow::before {
  content: none;
}
.index {
  width: 100vw;
  height: 100vh;
  /* overflow: hidden; */
  min-width: 1000px;
  max-width: 100%;
}

.el-header {
  background-color: #fff;
  color: #333;
  line-height: 6.1vh;
  padding: 0;
  display: flex;
}
.el-header .logo {
  width: 264px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-header .logo img {
  margin: 0 auto;
}
.el-header .logo_right {
  width: calc(100% - 12.5vw);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-header .logo_right h1 {
  font-family: "SHSCN_Bold";
  font-weight: bold;
  letter-spacing: 1px;
  margin-left: 77px;
}
.el-header .logo_right .right_logout {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* el-header头部--结束 */

/* el-aside侧边栏--开始 */
.el-aside {
  background-color: #fff;
  height: calc(100vh - 3.44vw);
  max-width: 295px;
  background-color: #d3dce6;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #171717;
  /* overflow-y:scroll ; */
}
.el-aside::-webkit-scrollbar {
  width: 2px;
  height: 0px;
  background-color: #b5b1b1;
}
.el-aside::-webkit-scrollbar-thumb {
  height: 10px;
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 1px #0666f5;
  background: #0666f5;
}
/* .el-aside::-webkit-scrollbar-track {

} */

.el-submenu__title {
  font-family: "SHSCN_Regular";
  font-weight: 400;
  color: #171717;
  letter-spacing: 1px;
  height: 5.55vh;
  line-height: 5.55vh;
}
.el-menu-item-group .el-menu-item-group__title {
  padding: 0 0 0 0;
}
.el-submenu__title:hover {
  background-color: #fff;
  translate: 1s;
}
.el-menu-item {
  font-family: "SHSCN_Regular";
  font-weight: 400;
  background-color: #eff3fc;
  height: 1px;
  transition: 1s;
}
.el-menu-item:hover {
  background-color: #fff;
  background-image: url("../../assets/image/EquipmentStorage_slices/group_275.png");
  color: #fff;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
/* 伪元素小图标 */
.el-submenu__icon-arrow {
  content: url("../../assets/image/EquipmentStorage_slices/path_1.png");
  background-repeat: no-repeat;
  background-size: 11.77px 7.35px;
  margin-top: 0.092vh;
}
/* 取消项目报备的伪元素 */
.project_filing .el-submenu__icon-arrow {
  content: none;
}
.project_filing .el-submenu__icon-arrow::before {
  content: none;
}
/* el-aside侧边栏--结束 */

/* el-mian主体区域开始 */
.el-main {
  background-color: #eee;
  padding: 40px 10px 20px !important;
  overflow: hidden;
}


/* el-mian主体区域结束 */
</style>
